import React, { useEffect } from 'react';
import { css } from 'emotion';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'gatsby';

import logo from '../assets/images/logo-white.svg';
import Header from '../components/Common/Header';
import { themeVars } from '../config/styles';

import Title from '../components/Common/Title';

const NotFoundPage = () => {
  // Scroll to the top of the page on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const cssWrapper = css`
    background: ${themeVars.root.darkAlt};
    height: 100vh;

    padding-top: 20rem;
  `;

  const cssNothing = css`
    position: relative;
    display: block;
    text-align: center;
  `;

  const cssLink = css`
    position: relative;
    display: inline-block;

    font-size: 1.6rem;
    color: white;

    &:hover {
      text-decoration: underline;
    }
  `;

  return (
    <>
      <Header logo={logo} isHome />

      <section className={cssWrapper}>
        <Container fluid>
          <Row>
            <Col xs={12} md={10} push={{ md: 1 }}>
              <div className={cssNothing}>
                <Title size={1} color={themeVars.root.light}>
                  404 page not found
                </Title>
                <Link to="/" className={cssLink}>
                  <span>Go to the homepage</span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NotFoundPage;
